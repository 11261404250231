import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54fcee5b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "board" }
const _hoisted_4 = ["data-row", "data-column", "onKeydown"]
const _hoisted_5 = {
  key: 0,
  class: "final"
}
const _hoisted_6 = {
  key: 1,
  class: "suggestions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reset()))
      }, "RESET")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SIZE, (_, row) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: row }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SIZE, (_, column) => {
              return (_openBlock(), _createElementBlock("div", {
                key: column,
                tabindex: "0",
                class: "cell",
                "data-row": row,
                "data-column": column,
                onKeydown: ($event: any) => (_ctx.onKey(row, column, $event))
              }, [
                (_ctx.board[row][column].value !== undefined)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.board[row][column].value + 1), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SIZE, (_, s) => {
                        return (_openBlock(), _createElementBlock("div", { key: s }, [
                          (_ctx.suggestionsBoard?.[row][column].value[s])
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(s + 1), 1)
                              ], 64))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
              ], 40, _hoisted_4))
            }), 128))
          ], 64))
        }), 128))
      ])
    ])
  ], 64))
}