
import { createBoard } from "@/utils/projects/sudoku/board";
import { getSuggestions, SIZE } from "@/utils/projects/sudoku/main";
import { defineComponent } from "vue";

function getEmptyBoard() {
  return createBoard<number | undefined>(SIZE, () => undefined);
}

export default defineComponent({
  data() {
    const board = getEmptyBoard();

    return {
      SIZE,
      board,
      suggestionsBoard: getSuggestions(board),
    };
  },

  methods: {
    updateSuggestions() {
      this.suggestionsBoard = getSuggestions(this.board);
    },

    reset() {
      this.board = getEmptyBoard();

      this.updateSuggestions();
    },

    onKey(row: number, column: number, $event: KeyboardEvent) {
      // Check arrows for movement
      if (
        ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(
          $event.code
        )
      ) {
        this.onArrowKey(row, column, $event);

        return;
      }

      // Check values for numbers

      if (
        [
          "Digit0",
          "Digit1",
          "Digit2",
          "Digit3",
          "Digit4",
          "Digit5",
          "Digit6",
          "Digit7",
          "Digit8",
          "Digit9",
        ].includes($event.code)
      ) {
        this.onNumberKey(row, column, $event);

        return;
      }

      console.log("Invalid key", $event.code);
    },

    onArrowKey(row: number, column: number, $event: KeyboardEvent) {
      let nextRow = row;
      let nextColumn = column;

      switch ($event.code) {
        case "ArrowUp":
          if (nextRow > 0) {
            nextRow--;
          }

          break;

        case "ArrowDown":
          if (nextRow < SIZE - 1) {
            nextRow++;
          }

          break;

        case "ArrowLeft":
          if (nextColumn > 0) {
            nextColumn--;
          }

          break;

        case "ArrowRight":
          if (nextColumn < SIZE - 1) {
            nextColumn++;
          }

          break;
      }

      this.$el.parentElement
        .querySelector(`[data-row="${nextRow}"][data-column="${nextColumn}"]`)
        ?.focus();
    },

    onNumberKey(row: number, column: number, $event: KeyboardEvent) {
      const valuesMap: Record<string, number | undefined> = {
        Digit0: undefined,
        Digit1: 0,
        Digit2: 1,
        Digit3: 2,
        Digit4: 3,
        Digit5: 4,
        Digit6: 5,
        Digit7: 6,
        Digit8: 7,
        Digit9: 8,
      };

      const value = valuesMap[$event.code];

      // Avoid setting incorrect numbers
      if (
        value !== undefined &&
        this.suggestionsBoard[row][column].value[value] !== true
      ) {
        return;
      }

      this.board[row][column].value = value;

      this.updateSuggestions();
    },
  },
});
